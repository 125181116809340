jQuery(document).ready(function($){

    $('#dropdowns .collapse').collapse();
    $('#dropdowns .dropdown').dropdown('show');
    window.onload = function () {
      var els = document.getElementsByClassName("indeterminate");
      Array.from(els).forEach(function(element) {
        element.indeterminate = true;
      });
    };

    hljs.initHighlightingOnLoad('html', 'css');

    var clipboard = new ClipboardJS('.clipboard-btn');

    clipboard.on('success', function(e) {
      setTooltip('Copied!', e.trigger);
      hideTooltip(e.trigger);
      e.clearSelection();
    });

    clipboard.on('error', function(e) {
      setTooltip("Copy failed :(", e.trigger);
      hideTooltip(e.trigger);
      e.clearSelection();
    });

    $(function () {
      $('.clipboard-btn').tooltip({
        title: 'Copy to clipboard'
      })
    });

    function setTooltip(message, e) {
      $(e).tooltip('hide').attr('data-original-title', message).tooltip('show');
    }

    function hideTooltip(e) {
      setTimeout(function () {
        $(e).tooltip('hide');
      }, 1000);
    }

    $('.icons-box__list span').on('click', function(){
        var iconCard = $('.icons-box__item');
        var code = $(this).data('clipboard-text');
        var iconName = $(this).attr('class');
        
        iconCard.find('.card-title').text(iconName.replaceAll('-', ' '));
        iconCard.find('.card-body').html(code);
        iconCard.find('.class-name').text(iconName);
        iconCard.find('code .hljs-string').text('"' + iconName + '"');
    })

    
    $('.card-footer pre').each(function(pre) {
        $(this).find('code').hover(function(e) {
            $(this).parent().addClass('show');
        }, function(e) {
            $(this).parent().removeClass('show')
            $(this).parent().find('button').removeClass('copied')
            $(this).parent().find('button').text('')
        })
        
        var btn = document.createElement('button');
        btn.classList.add('copy-code');
        $(this).append(btn);
    });

    $('.copy-code').on('click', function(e){

        var temp = $("<input>");
        $("body").append(temp);
        temp.val($(this).parents('pre').find('code').text()).select();
        document.execCommand("copy");
        temp.remove();

        $(this).addClass('copied')
        $(this).html('Copied!')
    })

    // Sticky Menu
    $('.navbar a').on('click', function(e) {
      e.preventDefault();
      
      var target = $(this.getAttribute('href'));
      if (target.length) {
        $('html, body').stop().animate({
          scrollTop: target.offset().top - 80
        }, 1000);
      }
    });
});